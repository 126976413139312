import GenericRaceResultService from "../api/services/genericRaceService";
import { downloadFile } from "../utilites/downloadFile";

const list = {
  state: {
    results: [],
    headers: [],
    downloadInProgress: false,
  },
  getters: {
    getResults (state) {
      return state.results
    },
    getVirtualHeaders (state) {
      return state.headers
    },
    getDownloadInProgress (state) {
      return state.downloadInProgress
    }
  },
  mutations: {
    setResults (state, data) {
      state.results = []
      state.results = data
    },
    setGenericHeaders (state, data) {
      state.headers = []
      state.headers = data
    },
    setDownloadStatus(state, status) {
      state.downloadInProgress = null
      state.downloadInProgress = status
    }
  },
  actions: {
    async fetchResults ({ commit }, { page, searchBib, searchName }) {
      let pageLink = 'pageNumber=50'
      if (page) {
        pageLink = `${pageLink}&page=${page}`
      }
      //  if page is set we just add the search parameters at the end of the link
      if (searchBib && searchName && Number.isInteger(searchBib) && searchBib > 0) {
        pageLink = `${pageLink}&bib=${searchBib}&name=${searchName}`
      } else if (searchBib && Number.isInteger(searchBib) && searchBib > 0) {
        pageLink = `${pageLink}&bib=${searchBib}`
      } else if (searchName) {
        pageLink = `${pageLink}&name=${searchName}`
      }
      return await GenericRaceResultService.getGenericRace(pageLink)
        .then(data => {
          commit('setResults', data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
    async fetchHeader ({ commit }) {
      return await GenericRaceResultService.getGenericRaceHeader()
        .then(data => {
          commit('setGenericHeaders', data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
    async download({ commit }, { rowId }) {
      commit('setDownloadStatus', true)
      return await GenericRaceResultService.download(rowId)
        .then(data => {
          downloadFile(data.data, 'oklevél.pdf', 'application/pdf')
          commit('setDownloadStatus', false)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    }
  }
}
  
export default list
  