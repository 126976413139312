import axios from 'axios';

const API_URL = process.env.VUE_APP_GENERIC_RACE_API_BASE_URL;

class GenericRaceResultService {
    async getGenericRace(url) {
        let result = await axios.get(API_URL + `/resultlists/bp150_2023/rows?${url}`);
        return result;
    }
    async getGenericRaceHeader () {
        let result = await axios.get(API_URL + '/resultlists/bp150_2023/columns');
        return result;
    }
    download(rowId, data, params) {
      return axios
        .request({
          method: 'get',
          url: API_URL + `/resultlists/bp150_2023/pdf/${rowId}`,
          data,
          headers: {
              'Accept': 'application/pdf',
            },
          responseType: 'blob',
          params
        })
    }

}

export default new GenericRaceResultService();