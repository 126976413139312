<template>

    <a target="popup"
       @click="openNewWindow(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}/${id}`)"
       :href="`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}/${id}`"
       class="fb-xfbml-parse-ignore"><img src="@/assets/facebook.svg" class="fb-logo"/></a>
  
  </template>
  
  <script>
  
    export default {
      name: 'FacebookShare',
      props: ['id'],
      computed: {
        shareUrl() {
          return `${process.env.VUE_APP_API_WEB_URL}/resultlists/bp150_2023/facebookShare`
        }
      },
      methods: {
        openNewWindow(url) {
          window.open(url, 'popup', 'width=400,height=400')
          return false
        }
      }
    }
  
  </script>
  
  <style scoped>
  
  .fb-logo{
    max-width: 35px;
  }
  
  </style>
  