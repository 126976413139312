export function downloadFile(data, filename, mime) {
    const blob = new Blob([data], { type: mime })
  
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob)
      return
    }
  
    const URL = window.URL || window.webkitURL
    const downloadUrl = URL.createObjectURL(blob)
  
    const downloadLink = document.createElement('a')
    downloadLink.style.display = 'none'
    downloadLink.download = filename
    downloadLink.href = downloadUrl
  
  
    if (typeof downloadLink.download === 'undefined') {
      downloadLink.target = '_blank'
    }
  
    document.body.append(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  
    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl)
    }, 100)
  }
  