<template>

  <button class="btn btn-primary" @click="download({rowId: id}); setActiveDownload()" :class="{ activeDownload: activeDownload }">
    <i class="bi bi-download"></i>
  </button>

  
  
</template>
  
<script>

  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'CertificateDownload',
    props: ['id'],
    data: function() {
      return {
        active: false
      }
    },
    computed: {
      ...mapGetters(['getDownloadInProgress']),
      activeDownload() {
        return this.getDownloadInProgress && this.active
      }
    },
    methods: {
      ...mapActions(['download']),
      setActiveDownload() {
        this.active = true
      }
    },
    watch: {
      getDownloadInProgress(newData) {
        if (!newData) {
          this.active = false
        }
      }
    }
  }

</script>


<style scoped>
  .activeDownload {
    animation: BibBounce 0.3s;
    animation-direction: alternate;
    animation-iteration-count: infinite;

    background-color: darkblue;
  }

  @keyframes BibBounce {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-2px);
    }
    50% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(2px);
    }
  }

</style>