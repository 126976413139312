<template>
  <tr v-for="item in results" :key="item">
    <td>
      <certificate-download :id="item.row_ix"/>
    </td>
    <td>
      <facebook-share :id="item.row_ix" />
    </td>
    <td v-for="cell in item.cells" :key="cell"> {{ cell }} </td>
  </tr>  
</template>

<script>

  //import { mapGetters } from 'vuex'
  import CertificateDownload from '@/modules/genericRace/components/CertificateDownload.vue'
  import FacebookShare from '@/modules/genericRace/components/FacebookShare.vue'

  export default {
    name: 'ResultTableItem',
    props: ['results'],
    components: {
      CertificateDownload,
      FacebookShare,
    },
    /*
    computed: {
      ...mapGetters(['getMeta']),
      shareUrl() {
        return `${process.env.VUE_APP_FRONT_BASE_URL}/share/${this.$route.params.raceId}/`
      }
    },
    methods: {
      openNewWindow(url){
        window.open(url,'popup','width=400,height=400'); return false;
      }
    }
    */
  }

</script>
  