import { createRouter, createWebHistory } from "vue-router";
import genericRaceRoutes from "@/modules/genericRace/routes"

const routes = [
  {
    path: '/',
    redirect: { path: '/list/bp150_2023' }
  },
  ...genericRaceRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
